<template>
  <div class="learner-class_list">
  <!-- 进入页面加载20条，到底上划再加载20条 -->
    <div class="class_list" v-show="list.length">
      <Card v-for="item in list" :key="item.id" :info="item"/>
    </div>
    <div class="empty" v-show="!list.length">
      <div class="icon"></div>
      <p class="tips">暂无导师提供排期，请等待班主任安排~</p>
    </div>
  </div>
</template>
<script>
import Card from './Card.vue'
import {getGroupAll} from '../../../api/learner'

export default {
  data() {
    return {
      list: []
    }
  },
  methods: {
    async initData() {
      const {courseId} = this.$route.query
      if (!courseId) {
        this.$toast('没有找到对应信息, 即将跳转')
        setTimeout(() => {
          this.$router.go(-1)
        }, 3000)
      }
      const data = await getGroupAll({
        isEnd: false,
        courseId
      })
      this.list = data
    },
  },
  mounted() {
    document.title = '加入班级'
    this.initData()
  },
  components: {Card}
}
</script>
<style lang="stylus" scoped>
@import "../../../theme.styl"
@import "../../../common.styl"
.class_list
  min-height 100vh
  background #fff
 .empty
    padding-top 123px
    .icon
      width 132px
      height 116px
      margin 0 auto
      bgFill()
      background-image url('../../../assets/images/empty.png')
    .tips
      padding-top 32px
      text-align center
      font-size fz16px
      color #C6C6C6
      line-height 22px
</style>