<template>
  <div class="learner_mine_card" @click="routerChange">
    <div class="row">
      <div class="img_wrap" :style="`background-image: url(${info.img})`"></div>
      <div class="col">
        <div class="title">{{info.groupName}}</div>
        <div class="process">
          <p class="name" :class="{'full':stateTxt === '已满员'}">{{info.classedPeople}}/{{info.peopleLimit}}{{stateTxt}}</p>
          <div class="avatar_wrap" v-if="classTotal > 1">
            <div class="avatar" :style="`zIndex: ${3-index}`"
              v-for="(item,index) in [1, 2,3]" :key="item"></div>
          </div>
        </div>
        <p v-if="classTotal > 1">预期时间：{{info.timeRegion}} 共{{classTotal}}节</p>
        <p v-else>
          上课时间：{{info.timeRegion}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classTotal: null
    }
  },
  computed: {
    stateTxt() {
      if (!this.info.isJoined) return '待加入'
      const state = this.info.classedPeople  >= this.info.peopleLimit 
      if (state) return '已满员'
      return '在学习'
    },
  },
  methods: {
    routerChange() {
      const {id} = this.info
      this.$router.push({
        name: `learner-class-detail`,
        query: {
          id, classTotal: this.classTotal
        }
      })
    }
  },
  mounted() {
    this.classTotal = this.$route.query.classTotal
  },
  props: ['info']
}
</script>
<style lang="stylus" scoped>
@import "../../../theme.styl"
@import "../../../common.styl"
.learner_mine_card
  border-bottom 1px solid #F3F3F3
  padding 15px
  height: 110px; 
  cursor pointer
  .img_wrap
    width: 110px;
    height: 100%; 
    border-radius 4px
    bgFill()
    margin-right 12px
    background-color #999
  .title
    font-size: fz16px;
    color: #444F6A;
    line-height 1
    width 238px
    vendor(1)
  .row
    height 100%
    justify-content start
    .process
      font-size fz14px
      color: #797C80;
      display flex
      justify-content space-between
      width 100%
      .avatar_wrap
        display flex
        justify-content space-between
        .avatar
          width: 20px;
          height: 20px;
          border: 1px solid #FFFFFF;
          border-radius 50%
          margin-left -6px
          position relative
          bgFill()
          background-color #999
          // background-image url('../../../assets/images/同学.png')
      .name
        vendor(1)
        color: #797C80
        font-size 14px
        &.full
          color: theme
          font-size fz14px
          line-height 1
    p
      width 100%
      color: #797C80;
      font-size 12px
  .col
    flex-direction column
    justify-content space-between
    height 100%
</style>